import Vue from 'vue';
import Vuex, { Module } from 'vuex';

import NissanSiteStore from './NissanSiteStore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    NissanSiteStore,
  },
});
