import * as THREE from 'three';
import { Tools } from './Tools';
import { InputPlugin } from './InputPlugin';

export class ScenoVR {
  private container: HTMLDivElement;
  private scenoVRVideo: HTMLVideoElement;

  private onVideoEndedCb: () => void;

  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private target: THREE.Vector3;
  private videoTexture: THREE.VideoTexture;
  private sphereMesh: THREE.Mesh;
  private renderer: THREE.WebGLRenderer;

  private scenoVRIsPlaying: boolean = false;
  private inputPlugin: InputPlugin;
  private controlType: string = 'TOUCH';
  private onVideoEndedEventBinded: any;
  private onWindowResizeBinded: any;

  private lon: number;
  private lat: number;

  constructor(container: HTMLDivElement, video: HTMLVideoElement) {
    this.container = container;
    this.scenoVRVideo = video;

    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      1100,
    );
    this.target = new THREE.Vector3(0, 0, 0);
    this.scene = new THREE.Scene();

    this.videoTexture = new THREE.VideoTexture(this.scenoVRVideo);
    this.videoTexture.minFilter = THREE.LinearFilter;

    const geometry = new THREE.SphereGeometry(500, 60, 40);
    geometry.applyMatrix(new THREE.Matrix4().makeScale(-1, 1, 1));

    const material = new THREE.MeshBasicMaterial({
    map: this.videoTexture,
    });

    this.sphereMesh = new THREE.Mesh(geometry, material);
    this.scene.add(this.sphereMesh);

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(window.devicePixelRatio );
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.container.appendChild(this.renderer.domElement);

    this.onWindowResizeBinded = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.onWindowResizeBinded);

    this.inputPlugin = new InputPlugin();
    this.scenoVRUpdateCamera();
  }

  public willDestroy(): void {
    window.removeEventListener('ended', this.onVideoEndedEventBinded);
    window.removeEventListener('resize', this.onWindowResizeBinded);
    this.inputPlugin.removeListeners();
  }

  public onVideoEnded(cb: () => void): void {
    this.onVideoEndedCb = cb;
  }

  public supportsDeviceOrientation(): boolean {
    return (Tools.isMobile()
      && typeof window.DeviceOrientationEvent !== 'undefined');
  }

  public hasPermissions(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.supportsDeviceOrientation()) {
        resolve(true);
      }

      if (typeof (window.DeviceOrientationEvent as any).requestPermission === 'function') {
        (window.DeviceOrientationEvent as any)
          .requestPermission()
          .then((permissionState: any) => {
            if (permissionState === 'granted') {
              this.inputPlugin.startDeviceOrientation();
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          })
        ;
      } else {
        // handle regular non iOS 13+ devices
        this.inputPlugin.startDeviceOrientation();
        resolve(true);
      }
    });
  }

  public playVideo(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scenoVRVideo.currentTime = 0;
      this.scenoVRVideo.play();

      const videoSpyTime = setInterval(() => {
        if (this.scenoVRVideo.currentTime > 0) {
          clearInterval(videoSpyTime);
          resolve();
        }
      }, 10);

      this.onVideoEndedEventBinded = this.onVideoEndedEvent.bind(this);
      this.scenoVRVideo.addEventListener('ended', this.onVideoEndedEventBinded);

      this.scenoVRLoop();
      this.scenoVRIsPlaying = true;
      this.inputPlugin.setThetaPhi(
        THREE.Math.degToRad(180),
        THREE.Math.degToRad(90),
      );
    });
  }

  public setControlType(type: string): void {
    this.controlType = type;
    if (this.controlType === 'DEVICE_ORIENTATION') {
      this.inputPlugin.resetAlpha();
    }
  }

  private onVideoEndedEvent(): void {
    this.scenoVRIsPlaying = false;
    this.onVideoEndedCb();
  }

  private onWindowResize(): void {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  private scenoVRLoop(): void {
    requestAnimationFrame(this.scenoVRLoop.bind(this));
    this.scenoVRUpdate();
  }

  private scenoVRUpdate(): void {
    if (!this.scenoVRIsPlaying) {
      return;
    }

    if (this.inputPlugin.isDragging()) {
      this.scenoVRUpdateCamera();
    }

    if (this.controlType === 'TOUCH') {
      this.camera.lookAt(this.target);
    } else {
      this.camera.rotation.reorder('YXZ');
      this.camera.quaternion.set(
        this.inputPlugin.getDeviceOrientation().x,
        this.inputPlugin.getDeviceOrientation().y,
        this.inputPlugin.getDeviceOrientation().z,
        this.inputPlugin.getDeviceOrientation().w,
      );
    }

    this.renderer.render(this.scene, this.camera);
  }

  private scenoVRUpdateCamera(): void {
    this.target.set(
      500 * Math.sin(this.inputPlugin.getPhi()) * Math.cos(this.inputPlugin.getTheta()),
      500 * Math.cos(this.inputPlugin.getPhi()),
      500 * Math.sin(this.inputPlugin.getPhi()) * Math.sin(this.inputPlugin.getTheta()),
    );
  }
}
