















































import { State, Action, Getter } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ScenoVRViewer from '../components/ScenoVRViewer.vue';

@Component({
  components: {
    ScenoVRViewer,
  },
})
export default class Home extends Vue {
  @Action('setScenoVROpened')
  public setScenoVROpened: any;

  @Getter('isScenoVROpened')
  public isScenoVROpened: boolean;

  @Action('setHasOpended360Page')
  public setHasOpended360Page: any;

  @Action('setHasDownloadedFlat')
  public setHasDownloadedFlat: any;

  @Action('setHasClickOnTwitterBtn')
  public setHasClickOnTwitterBtn: any;

  @Action('setHasClickOnFacebookBtn')
  public setHasClickOnFacebookBtn: any;

  @Action('setHasStartedFlat')
  public setHasStartedFlat: any;

  @Action('setHasCompletedFlat')
  public setHasCompletedFlat: any;

  private flatVideo: HTMLVideoElement;

  private mounted(): void {
    this.flatVideo = this.$refs.flatVideo as HTMLVideoElement;
    this.flatVideo.addEventListener('play', () => this.setHasStartedFlat());
    this.flatVideo.addEventListener('ended', () => this.setHasCompletedFlat());
  }

  private open360Video(): void {
    this.flatVideo.pause();
    this.setHasClickOnTwitterBtn();
    this.setScenoVROpened(true);
    this.setHasOpended360Page(true);
  }

  private downloadVideo(): void {
    this.setHasDownloadedFlat(true);
    (this.$refs.videoDlLink as HTMLElement).click();
  }

  private doShareFacebook(): void {
    this.setHasClickOnFacebookBtn(true);
    window.open(
      'https://facebook.com/dialog/share?app_id=517572238647306&display=popup&href=' + location.href,
      '_blank',
    );
  }

  private doShareTwitter(): void {
    this.setHasClickOnTwitterBtn(true);
    window.open(
      'https://twitter.com/intent/tweet?url=' + encodeURIComponent(location.href),
      '_blank',
    );
  }

  private getVideoFlatURL(): string {
    return (window as any).NissanHeroVideoFlatURL;
  }

  private getUserName(): string {
    return (window as any).NissanHeroUserName;
  }
}
