export class Tools {
  public static isAndroid(): boolean {
    return /(android|Android)/g.test(navigator.userAgent);
  }

  public static isiOS(): boolean {
    return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
  }

  public static isMobile(): boolean {
    return Tools.isAndroid() || Tools.isiOS();
  }

  public static clamp(a: number, b: number, c: number): number {
    return Math.max(b, Math.min(c, a));
  }
}
