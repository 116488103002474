import { Tools } from './Tools';

import * as THREE from 'three';

export class InputPlugin {
  private MOUSE_SPEED_X: number = 0.5;
  private MOUSE_SPEED_Y: number = 0.3;

  private rotateStart: THREE.Vector2 = new THREE.Vector2();
  private rotateEnd: THREE.Vector2 = new THREE.Vector2();
  private rotateDelta: THREE.Vector2 = new THREE.Vector2();
  private deviceRotation: THREE.Quaternion = new THREE.Quaternion();
  private mIsDragging: boolean = true;
  private phi: number = THREE.Math.degToRad(90);
  private theta: number = -THREE.Math.degToRad(180);
  private alphaOffset: number = THREE.Math.degToRad(90);
  private currentAlpha: number = 0;
  private q1: THREE.Quaternion = new THREE.Quaternion(
    -Math.sqrt(0.5),
    0,
    0,
    Math.sqrt(0.5),
  );

  private onTouchMoveBinded: any;
  private onTouchDownBinded: any;
  private onTouchUpBinded: any;
  private onDeviceOrientationBinded: any;

  private onMouseMoveBinded: any;
  private onMouseDownBinded: any;
  private onMouseUpBinded: any;

  constructor() {
    if (Tools.isMobile()) {
      this.onTouchMoveBinded = this.onTouchMove.bind(this);
      this.onTouchDownBinded = this.onTouchDown.bind(this);
      this.onTouchUpBinded = this.onTouchUp.bind(this);

      window.addEventListener('touchmove', this.onTouchMoveBinded);
      window.addEventListener('touchstart', this.onTouchDownBinded);
      window.addEventListener('touchend', this.onTouchUpBinded);
    } else {
      this.onMouseMoveBinded = this.onMouseMove.bind(this);
      this.onMouseDownBinded = this.onMouseDown.bind(this);
      this.onMouseUpBinded = this.onMouseUp.bind(this);

      window.addEventListener('mousemove', this.onMouseMoveBinded);
      window.addEventListener('mousedown', this.onMouseDownBinded);
      window.addEventListener('mouseup', this.onMouseUpBinded);
    }
  }

  public isDragging(): boolean {
    return this.mIsDragging;
  }

  public getPhi(): number {
    return this.phi;
  }

  public getTheta(): number {
    return this.theta;
  }

  public getDeviceOrientation(): THREE.Quaternion {
    return this.deviceRotation;
  }

  public removeListeners(): void {
    if (Tools.isMobile()) {
      window.removeEventListener('touchmove', this.onTouchMoveBinded);
      window.removeEventListener('touchstart', this.onTouchDownBinded);
      window.removeEventListener('touchend', this.onTouchUpBinded);
      window.removeEventListener('deviceorientation', this.onDeviceOrientationBinded  );
    } else {
      window.removeEventListener('mousemove', this.onMouseMoveBinded);
      window.removeEventListener('mousedown', this.onMouseDownBinded);
      window.removeEventListener('mouseup', this.onMouseUpBinded);
    }
  }

  public setThetaPhi(t: number, p: number): void {
    this.theta = t;
    this.phi = p;
  }

  public startDeviceOrientation(): void {
    this.onDeviceOrientationBinded = this.onDeviceOrientation.bind(this);
    window.addEventListener('deviceorientation', this.onDeviceOrientationBinded);
  }

  public resetAlpha(): void {
    //this.alphaOffset = THREE.Math.degToRad(this.currentAlpha + 90);
  }

  // Helpers -------------------------------------------------------------------
  private isPointerLocked(): boolean {
    return document.pointerLockElement !== null;
  }

  // Device orientation --------------------------------------------------------
  private onDeviceOrientation(e: DeviceOrientationEvent): void {
    const alpha: number = e.alpha
      ? THREE.Math.degToRad(e.alpha) + this.alphaOffset
      : 0
    ;
    this.currentAlpha = e.alpha ? e.alpha : 0;

    const beta = e.beta
      ? THREE.Math.degToRad(e.beta)
       : 0
    ;

    const gamma = e.gamma
      ? THREE.Math.degToRad(e.gamma)
       : 0
    ;

    // var orient = scope.screenOrientation ? _Math.degToRad( scope.screenOrientation ) : 0; // O
    const orient: number = 0;

    const euler: THREE.Euler = new THREE.Euler();
    euler.set(beta, alpha, -gamma, 'YXZ');
    this.deviceRotation.setFromEuler( euler );
    this.deviceRotation.multiply(this.q1);
    this.deviceRotation.multiply(
      new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(0, 0, 1),
         -orient,
        ),
      )
    ;
  }

  // Touch/Tap Down section ----------------------------------------------------
  private onTouchDown(e: TouchEvent): void {
    if (!e.touches) {
      return;
    }
    if (e.touches.length === 0) {
      return;
    }

    this.onMouseDown({
      clientX: e.touches[0].clientX,
      clientY: e.touches[0].clientY,
    });
  }

  private onMouseDown(e: MouseEvent|any): void {
    this.rotateStart.set(e.clientX, e.clientY);
    this.mIsDragging = true;
  }

  // Touch/Tap Up section ------------------------------------------------------
  private onTouchUp(e: TouchEvent): void {
    if (!e.touches) {
      return;
    }
    if (e.touches.length === 0) {
      return;
    }

    this.onMouseUp({
      clientX: e.touches[0].clientX,
      clientY: e.touches[0].clientY,
    });
  }

  private onMouseUp(e: MouseEvent|any): void {
    this.mIsDragging = false;
  }

  // Touch/Tap Down section ----------------------------------------------------
  private onTouchMove(e: TouchEvent): void {
    if (!e.touches) {
      return;
    }
    if (e.touches.length === 0) {
      return;
    }

    this.onMouseMove({
        clientX: e.touches[0].clientX,
        clientY: e.touches[0].clientY,
    });
    e.preventDefault();
  }

  private onMouseMove(e: MouseEvent|any): void {
    if (!this.mIsDragging && !this.isPointerLocked()) {
        return;
    }

    // Support pointer lock API.
    if (this.isPointerLocked()) {
      const movementX = e.movementX || e.mozMovementX || 0;
      const movementY = e.movementY || e.mozMovementY || 0;
      this.rotateEnd.set(
        this.rotateStart.x - movementX,
        this.rotateStart.y - movementY,
      );
    } else {
      this.rotateEnd.set(e.clientX, e.clientY);
    }

    this.rotateDelta.subVectors(this.rotateEnd, this.rotateStart);
    this.rotateStart.copy(this.rotateEnd);

    this.phi -= 2 * Math.PI * this.rotateDelta.y / window.innerHeight * this.MOUSE_SPEED_Y;
    this.theta -= 2 * Math.PI * this.rotateDelta.x / window.innerWidth * this.MOUSE_SPEED_X;

    this.phi = Tools.clamp(this.phi, 0, Math.PI);
  }
}
