import {
  Module,
  VuexModule,
  MutationAction,
} from 'vuex-module-decorators';
import { NissanSiteModel } from '../models/NissanSiteModel';

@Module
export default class NissanSiteStore extends VuexModule {
  public mIsScenoVROpened: boolean = false;

  // Metrics
  public mHasOpended360Page: boolean = false;
  public mHasStarted360: boolean = false;
  public mHasCompleted360: boolean = false;
  public mHasStartedFlat: boolean = false;
  public mHasCompletedFlat: boolean = false;
  public mHasDownloaded360: boolean = false;
  public mHasDownloadedFlat: boolean = false;
  public mHasClickOnFacebookBtn: boolean = false;
  public mHasClickOnTwitterBtn: boolean = false;

  get isScenoVROpened(): boolean {
    return this.mIsScenoVROpened;
  }

  get hasOpended360Page(): boolean {
    return this.mHasOpended360Page;
  }

  get hasStarted360(): boolean {
    return this.mHasStarted360;
  }

  get hasCompleted360(): boolean {
    return this.mHasCompleted360;
  }

  get hasStartedFlat(): boolean {
    return this.mHasStartedFlat;
  }

  get hasCompletedFlat(): boolean {
    return this.mHasCompletedFlat;
  }

  get hasDownloaded360(): boolean {
    return this.mHasDownloaded360;
  }

  get hasDownloadedFlat(): boolean {
    return this.mHasDownloadedFlat;
  }

  get hasClickOnFacebookBtn(): boolean {
    return this.mHasClickOnFacebookBtn;
  }

  get hasClickOnTwitterBtn(): boolean {
    return this.mHasClickOnTwitterBtn;
  }

  @MutationAction({ mutate: ['mIsScenoVROpened'] })
  public async setScenoVROpened(isOpen: boolean): Promise<{ mIsScenoVROpened: boolean }> {
    return { mIsScenoVROpened: isOpen };
  }

  // Metrics
  @MutationAction({ mutate: ['mHasOpended360Page'] })
  public async setHasOpended360Page(b: boolean): Promise<{ mHasOpended360Page: boolean }> {
    return { mHasOpended360Page: b };
  }

  @MutationAction({ mutate: ['mHasStarted360'] })
  public async setHasStarted360(b: boolean): Promise<{ mHasStarted360: boolean }> {
    return { mHasStarted360: b };
  }

  @MutationAction({ mutate: ['mHasCompleted360'] })
  public async setHasCompleted360(b: boolean): Promise<{ mHasCompleted360: boolean }> {
    return { mHasCompleted360: b };
  }

  @MutationAction({ mutate: ['mHasStartedFlat'] })
  public async setHasStartedFlat(b: boolean): Promise<{ mHasStartedFlat: boolean }> {
    return { mHasStartedFlat: b };
  }

  @MutationAction({ mutate: ['mHasCompletedFlat'] })
  public async setHasCompletedFlat(b: boolean): Promise<{ mHasCompletedFlat: boolean }> {
    return { mHasCompletedFlat: b };
  }

  @MutationAction({ mutate: ['mHasDownloaded360'] })
  public async setHasDownloaded360(b: boolean): Promise<{ mHasDownloaded360: boolean }> {
    return { mHasDownloaded360: b };
  }

  @MutationAction({ mutate: ['mHasDownloadedFlat'] })
  public async setHasDownloadedFlat(b: boolean): Promise<{ mHasDownloadedFlat: boolean }> {
    return { mHasDownloadedFlat: b };
  }

  @MutationAction({ mutate: ['mHasClickOnTwitterBtn'] })
  public async setHasClickOnTwitterBtn(b: boolean): Promise<{ mHasClickOnTwitterBtn: boolean }> {
    return { mHasClickOnTwitterBtn: b };
  }

  @MutationAction({ mutate: ['mHasClickOnFacebookBtn'] })
  public async setHasClickOnFacebookBtn(b: boolean): Promise<{ mHasClickOnFacebookBtn: boolean }> {
    return { mHasClickOnFacebookBtn: b };
  }
}
