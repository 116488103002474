








import {
  Component,
  Vue,
} from 'vue-property-decorator';
import {
  State,
  Action,
  Getter,
} from 'vuex-class';
import KeenTracking from 'keen-tracking';

@Component({
  components: {
  },
})
export default class App extends Vue {
  @Getter('isScenoVROpened')
  public isScenoVROpened: boolean;

  @Getter('hasOpended360Page')
  public hasOpended360Page: boolean;

  @Getter('hasStarted360')
  public hasStarted360: boolean;

  @Getter('hasCompleted360')
  public hasCompleted360: boolean;

  @Getter('hasStartedFlat')
  public hasStartedFlat: boolean;

  @Getter('hasCompletedFlat')
  public hasCompletedFlat: boolean;

  @Getter('hasDownloaded360')
  public hasDownloaded360: boolean;

  @Getter('hasDownloadedFlat')
  public hasDownloadedFlat: boolean;

  @Getter('hasClickOnFacebookBtn')
  public hasClickOnFacebookBtn: boolean;

  @Getter('hasClickOnTwitterBtn')
  public hasClickOnTwitterBtn: boolean;

  private sendMetricsBinded: () => void;

  private client: KeenTracking = new KeenTracking({
    projectId: '5dd2241ec9e77c000107e318',
    writeKey: '19D30880CC0F3321F0B3EDF71674D75AE897AA09444E18C01F760D033845BEA696EE3EC3DB098B1844236B214187C8DDAD9DE0C34E02DEDF8E02BF9C4B63C3B2C514DABB939B731B729E62C9BA5C407A07768A1EEC01B437E673D500A543459E'
  });

  private created(): void {
    this.sendMetricsBinded = this.sendMetrics.bind(this);
    window.addEventListener('beforeunload', this.sendMetricsBinded)
  }

  private sendMetrics(): void {
    console.log(this.client);
    console.log(JSON.stringify({
          hasOpended360Page: this.hasOpended360Page,
          hasStarted360: this.hasStarted360,
          hasCompleted360: this.hasCompleted360,
          hasStartedFlat: this.hasStartedFlat,
          hasCompletedFlat: this.hasCompletedFlat,
          hasDownloaded360: this.hasDownloaded360,
          hasDownloadedFlat: this.hasDownloadedFlat,
          hasClickOnFacebookBtn: this.hasClickOnFacebookBtn,
          hasClickOnTwitterBtn: this.hasClickOnTwitterBtn,
      }));
    this.client
      .recordEvent('microsite_session', {
        hasOpended360Page: this.hasOpended360Page,
        hasStarted360: this.hasStarted360,
        hasCompleted360: this.hasCompleted360,
        hasStartedFlat: this.hasStartedFlat,
        hasCompletedFlat: this.hasCompletedFlat,
        hasDownloaded360: this.hasDownloaded360,
        hasDownloadedFlat: this.hasDownloadedFlat,
        hasClickOnFacebookBtn: this.hasClickOnFacebookBtn,
        hasClickOnTwitterBtn: this.hasClickOnTwitterBtn,
      })
      .then((response) => {
        console.log('Keen Event Recorded');
      })
      .catch(error => {
        console.log('Keen Event Error: ' + error);
      })
    ;
  }
}
