import Vue from 'vue';
import './plugins/vuetify';
import { i18n } from './plugins/i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/fonts/fonts.css';

declare module 'vue/types/vue' {
  interface Vue {
    $eventHub: any;
  }
}

Vue.prototype.$eventHub = new Vue();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h: any) => h(App),
}).$mount('#app');
