















































































import { State, Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ScenoVR } from './ScenoVR/ScenoVR';

@Component({})
export default class ScenoVRViewer extends Vue {
  @Action('setScenoVROpened')
  public setScenoVROpened: any;

  @Action('setHasStarted360')
  public setHasStarted360: any;

  @Action('setHasCompleted360')
  public setHasCompleted360: any;

  @Action('setHasDownloaded360')
  public setHasDownloaded360: any;

  private scenoVR: ScenoVR;

  private isLoading: boolean = false;
  private isPlaying: boolean = false;
  private hasEnded: boolean = false;
  private supportsDeviceOrientation: boolean = false;
  private controlType: string = 'TOUCH';

  private mounted(): void {
    this.scenoVR = new ScenoVR(
      this.$refs.ScenoVRContainer as HTMLDivElement,
      this.$refs.sourceVideo as HTMLVideoElement,
    );

    this.supportsDeviceOrientation = this.scenoVR.supportsDeviceOrientation();

    const self = this;
    this.scenoVR.onVideoEnded(() => {
      self.setHasCompleted360(true);
      self.hasEnded = true;
      self.isPlaying = false;
    });
  }

  private beforeDestroy(): void {
    this.scenoVR.willDestroy();
  }

  private setControlType(type: string): void {
    this.controlType = type;
    this.scenoVR.setControlType(type);
  }

  private onPressPlay(): void {
    this
      .scenoVR
      .hasPermissions()
      .then(
        (granted) => {
          if (granted) {
            this.hasEnded = false;
            this.isLoading = true;
            this.isPlaying = false;

            this
              .scenoVR
              .playVideo()
              .then(
                () => {
                  this.setHasStarted360(true);
                  this.isLoading = false;
                  this.isPlaying = true;
                },
              )
            ;
          } else {
            // BLERG
          }
        },
      )
    ;
  }

  private quit(): void {
    this.setScenoVROpened(false);
  }

  private download360Video() {
    this.setHasDownloaded360(true);
    (this.$refs.video360DlLink as HTMLElement).click();
  }

  private getVideo360URL(): string {
    return (window as any).NissanHeroVideo360URL;
  }
}
